






















































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  ApiGetScaleNameSelectDoctor,
  ApiGetScaleNameSelectPatient,
  ApiGetScaleListDoctor,
  ApiGetScaleListPatient,
  ApiScaleDownloadDetail,
  ApiScaleDownload,
} from "@/apis/index";
import { breadcrumb } from "@/utils/breadcrumb";
import { RouteConfig } from "vue-router";
import { Form } from "element-ui";
import fileDownload from "js-file-download";
import axios from "axios";

// 引入JSZip库
const JSZip = require("jszip");
const FileSaver = require("file-saver");

Component.registerHooks(["beforeRouteEnter"]);
@Component({
  name: "scaleAssessmentList",
})
export default class ScaleAssessmentList extends Vue {
  beforeRouteEnter(to: RouteConfig, from: RouteConfig, next: Function): void {
    const name: any = from.name;
    // 进入组件之前未被实例化，无法直接访问this
    next((vm: any) => {
      // console.log(name);
      const list = new Set(["scaleAssessmentView"]);
      const need_cache: boolean = list.has(name);
      console.log(need_cache);
      if (!need_cache) {
        vm.resetCondition();
      } else {
        vm.updateTableData(vm.currentIndex);
      }
    });
  }
  //data
  dialogVisible = false;
  dialogVisibleChoose = false;
  chooseDownload = [] as any;
  //量表评估-量表名称下拉框数据
  scaleAssessmentList = [] as any;
  searchform = {
    currentScaleName: "",
  };
  currentScaleID: undefined | number | string = "";
  //计算属性，去评估按钮是否禁用
  get showDisabled() {
    return this.searchform.currentScaleName == "";
  }
  //量表评估结果检索-量表名称下拉框数据
  scaleSearchList = [];
  condition: any = {
    patient_name: "",
    name: "",
    evaluate_start: "",
    evaluate_end: "",
  };
  tableData: Array<any> = [{}];
  pagination = {
    //当前是第几页
    page: 1,
    //分页器当前页显示几条数据
    size: 10,
    //表格的数据总量
    total: 0,
  };
  searchformRules = {
    currentScaleName: [
      { required: true, message: "请选择要使用的量表", trigger: "change" },
    ],
  };
  //hook
  mounted() {
    this.init();
  }
  //methods
  init() {
    this.getDoctorScaleNameList(0);
    this.updateTableData(0);
    this.downloadList();
  }
  cancelGoTo() {
    this.dialogVisible = false;
    this.searchform.currentScaleName = "";
    (this.$refs.searchformRef as Form).resetFields();
  }
  // 点击新增评估
  goToDetail() {
    this.dialogVisible = true;
  }
  downScale() {
    this.dialogVisibleChoose = true;
  }
  //获取量表评估-量表名称列表
  getDoctorScaleNameList(type: number) {
    switch (type) {
      case 0: {
        ApiGetScaleNameSelectDoctor().then((res) => {
          if (res.success) {
            this.scaleAssessmentList = res.data;
            this.scaleSearchList = res.data;
          }
        });
        break;
      }
      case 1: {
        ApiGetScaleNameSelectPatient().then((res) => {
          if (res.success) {
            this.scaleSearchList = res.data;
          }
        });
        break;
      }
    }
  }
  //获取列表数据
  updateTableData(type: number) {
    let endTime = this.condition.evaluate_end;
    if (endTime) {
      const timeLimit = 24 * 60 * 60 * 1000;
      endTime = endTime + timeLimit - 1;
    }
    const param = {
      patient_name: this.condition.patient_name,
      name: this.condition.name,
      evaluate_start: this.condition.evaluate_start,
      evaluate_end: endTime,
      cur_page: this.pagination.page,
      page_size: this.pagination.size,
    };
    console.log(param);
    switch (type) {
      case 0: {
        ApiGetScaleListDoctor(param).then((res) => {
          if (res.success) {
            this.tableData = res.data;
            this.pagination.total = res.page.total_size;
          }
        });
        break;
      }
      case 1: {
        ApiGetScaleListPatient(param).then((res) => {
          if (res.success) {
            this.tableData = res.data;
            this.pagination.total = res.page.total_size;
          }
        });
        break;
      }
    }
  }
  //当选择的使用量表发生变化时触发的函数
  selectChange(data: number) {
    console.log(data);
    this.searchform.currentScaleName;
    const item: any = this.scaleAssessmentList.find((item: any) => {
      return item.name == data;
    });
    if (item) {
      this.currentScaleID = item.module;
    }
  }
  //格式化空数据
  dash(data: any) {
    if (data === null || data === "") {
      data = "-";
    }
    return data;
  }
  formatDate(timeStamp: number | string | null) {
    if (timeStamp == null) {
      return "-";
    }
    const time = new Date(timeStamp);
    //年-月-日
    return `${time.getFullYear()}-${this.format(
      time.getMonth() + 1
    )}-${this.format(time.getDate())} ${this.format(
      time.getHours()
    )}:${this.format(time.getMinutes())}:${this.format(time.getSeconds())}`;
  }
  format(date: number | string) {
    date = date.toString();
    return ("0" + date).slice(-2);
  }
  formatScore(score: undefined | string) {
    let result: number | string = "";
    if (score === undefined) {
      result = "";
    } else {
      if (score != "-") {
        result = parseFloat(score);
      } else {
        result = "-";
      }
    }
    return result;
  }
  //点击查询按钮触发的事件
  clickSearch() {
    this.pagination.page = 1;
    this.updateTableData(this.currentIndex);
  }
  //点击重置按钮触发的事件
  resetCondition() {
    //重置选择新增的量表
    this.searchform.currentScaleName = "";
    //清空查询条件
    Object.keys(this.condition).map((item: any) => {
      this.condition[item] = "";
    });
    //重置分页器
    this.pagination.page = 1;
    this.pagination.size = 10;
    //重置tab
    this.currentIndex = 0;
    //重置表格数据
    this.updateTableData(this.currentIndex);
  }
  //分页器改变页显示数触发函数
  handleSizeChange(val: any) {
    this.pagination.size = val;
    this.updateTableData(this.currentIndex);
  }
  //分页器改变当前显示页触发函数
  handleCurrentChange(val: any) {
    this.pagination.page = val;
    this.updateTableData(this.currentIndex);
  }
  //tag模块
  currentIndex = 0;
  clickItem(index: number) {
    this.currentIndex = index;
    //重置分页器
    this.pagination.page = 1;
    this.pagination.size = 10;
    switch (index) {
      case 0: {
        this.getDoctorScaleNameList(0);
        this.updateTableData(0);
        break;
      }
      case 1: {
        this.getDoctorScaleNameList(1);
        this.updateTableData(1);
        break;
      }
    }
  }
  //量表评估-去评估按钮触发函数
  evaluate() {
    (this.$refs.searchformRef as Form).validate((valid: any) => {
      if (valid) {
        this.dialogVisible = false;
        //需要给新增传两个内容:量表id和量表名称
        breadcrumb.push(
          this,
          "/doctor-system/elemedirecord/scaleAssessment/add",
          "评估",
          {
            scaleId: this.currentScaleID,
            scaleName: this.searchform.currentScaleName,
          },
          false
        );
      }
    });
  }
  cancelDownload() {
    this.dialogVisibleChoose = false;
    this.chooseDownload = [];
  }
  downLists = [] as any;
  downloadList() {
    ApiScaleDownload().then((res) => {
      this.downLists = res.data;
    });
  }
  zlDownB(fileLists: any) {
    //处理文件格式
    const blogTitle = "量表文件"; // 下载后压缩包的名称
    const zip = new JSZip();
    const promises = [] as any;
    const cache = {} as any;
    for (const item of fileLists) {
      // item.feilePath为文件链接地址
      // item.fileName为文件名称
      if (item.feilePath) {
        const promise = this.getImgArrayBuffer(item.feilePath).then((data) => {
          // 下载文件, 并存成ArrayBuffer对象(blob)
          zip.file(item.fileName, data, { binary: true }); // 逐个添加文件
          cache[item.fileName] = data;
        });
        promises.push(promise);
        this.dialogVisibleChoose = false;
      } else {
        // feilePath地址不存在时提示
        alert(`附件${item.fileName}地址错误，下载失败`);
      }
    }
    Promise.all(promises)
      .then(() => {
        zip.generateAsync({ type: "blob" }).then((content: any) => {
          // 生成二进制流
          FileSaver.saveAs(content, blogTitle); // 利用file-saver保存文件  blogTitle:自定义文件名
        });
      })
      .catch((res) => {
        alert("文件压缩失败");
      });
  }
  //文件以流的形式获取（参数url为文件链接地址）
  getImgArrayBuffer(url: any) {
    return new Promise((resolve, reject) => {
      //通过请求获取文件blob格式
      const xmlhttp = new XMLHttpRequest();
      xmlhttp.open("GET", url, true);
      xmlhttp.responseType = "blob";
      xmlhttp.onload = function () {
        if (xmlhttp.status == 200) {
          resolve(xmlhttp.response);
        } else {
          reject(xmlhttp.response);
        }
      };
      xmlhttp.send();
    });
  }

  async submitDownload() {
    const filteredArray = this.downLists.filter((obj: any) =>
      this.chooseDownload.includes(obj.name)
    );
    const links = [] as any;
    filteredArray.forEach((link: any) => {
      filteredArray.forEach((link: any) => {
        const segments = link.download_url.split("/");
        const filename = segments[segments.length - 1];
        const fileType = filename.split(".").pop();

        links.push({
          fileName: link.name + "." + fileType,
          feilePath: link.download_url,
        });
      });
    });

    this.zlDownB(links);
  }
  //表格操作栏-查看按钮触发函数
  viewDetail(row: any) {
    breadcrumb.push(
      this,
      "/doctor-system/elemedirecord/scaleAssessment/view",
      "查看",
      {
        scaleId: row.id,
        //评估来源类型：医生评估结果/患者自评结果
        evaluationSourceType: this.currentIndex,
      },
      false
    );
  }
  downDetail(row: any) {
    ApiScaleDownloadDetail({ id: row.id, responseType: "blob" }).then(
      (res: any) => {
        if (res.success) {
          axios({
            url: res.data,
            method: "GET",
            responseType: "blob", // 设置响应类型为 blob
          })
            .then((response) => {
              fileDownload(
                response.data,
                row.patient_name + "-" + row.name + ".docx"
              );
            })
            .catch((error) => {
              console.error("Error downloading the file:", error);
            });
        } else {
          this.$message.error(res.message);
        }
      }
    );
  }
}
